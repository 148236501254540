import styles from "../styles/components/Topline/ToplineMenu.module.scss";
import { FunctionComponent } from "react";
import { usePlatform } from "@/helpers/platformContext";
import { useDevice } from "@/helpers/deviceContext";
import Contacts from "@/components/Contacts";
import Informers from "./Topline/Informers";

const Footer: FunctionComponent = () => {
  const { isMobile, isDesktop } = useDevice();
  const { domain, footer_column_first, contacts } =
    usePlatform();
  const isHaveContacts = !!contacts.editors_address;
  return (
    <div style={{ maxWidth: '1150px', margin: '0 auto', paddingInline: '1rem' }}>
      {isHaveContacts ? (
        <div className={styles.smallText}>
          <Contacts
            className={styles.smallText}
            contacts={contacts}
            domain={domain}
            isDesktop={isDesktop}
            showFull
          />
          {!footer_column_first?.slice(0, 20).includes("&copy;") && (
            <div dangerouslySetInnerHTML={{ __html: footer_column_first }} />
          )}
        </div>
      ) : (
        <div
          className={styles.smallText}
          dangerouslySetInnerHTML={{ __html: footer_column_first }}
        />
      )}
      <Informers />
    </div>
  );
};

export default Footer;
